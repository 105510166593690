<template>
    <div class="relative isolate overflow-hidden mx-auto grid grid-cols-1 p-3 rounded-md sm:w-full md:w-5/12">
    <div class="mx-auto max-w-1xl px-1 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
       
        <!-- <div class="max-w-xl lg:max-w-lg">
          <h2 class="text-1xs font-bold tracking-tight text-white sm:text-1xs">Bantu Kita</h2>
          <p class="mt-4 text-lg leading-8 text-gray-300">
            ...
          </p>
          <div class="mt-6 flex max-w-md gap-x-4">
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" name="email" type="email" autocomplete="email" required="" class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
            <button type="submit" class="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>
          </div>
        </div> -->
        <!-- <dl class="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
          <div class="flex flex-col items-start">
            <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
          
            </div>
            <dt class="mt-1 font-semibold text-white">Weekly articles</dt>
            <dd class="mt-1 leading-1 text-gray-400">Non laboris consequat cupidatat laborum magna. Eiusmod non irure cupidatat duis commodo amet.</dd>
          </div>
          <div class="flex flex-col items-start">
            <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
            
            </div>
            <dt class="mt-4 font-semibold text-white">No spam</dt>
            <dd class="mt-2 leading-7 text-gray-400">Officia excepteur ullamco ut sint duis proident non adipisicing. Voluptate incididunt anim.</dd>
          </div>
        </dl> -->
      </div>
    </div>
    <div class="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
      <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
  </div>
  <div>

        <div class="bg-white text-center fixed inset-x-0 bottom-0 z-10">
            <div class="container mx-auto grid grid-cols-4 gap-4 p-2 sm:w-full md:w-5/12">

                <router-link :to="{name: 'home'}"
                    class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
                    <img class="inline-block mb-1" width="25" height="25"
                        src="@/assets/images/home.png">
                    <span class="block text-xs">Beranda</span>
                </router-link>

                <div>
                    <router-link :to="{name: 'donation.index'}"
                        class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
                        <img width="25" height="25" class="inline-block mb-1"
                            src="@/assets/images/heart.png">
                        <span class="block text-xs">Donasi Saya</span>
                    </router-link>
                </div>

                <div>
                    <router-link :to="{name: 'campaign.index'}"
                        class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
                        <img width="25" height="25" class="inline-block mb-1"
                                src="@/assets/images/flag.png">
                        <span class="tab tab-kategori block text-xs">Campaign</span>
                    </router-link>
                </div>

                <div>
                    <router-link :to="{name: 'login'}"
                    class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center">
                    <img width="25" height="25" class="inline-block mb-1"
                            src="@/assets/images/user.png">
                    <span class="block text-xs">Akun</span>
                    </router-link>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
// import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/vue/24/outline'
export default {
    name: 'FooterComponent'
}
</script>

<style>

</style>